import {ExtensibleEntityDto} from './ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface EquipmentDto extends ExtensibleEntityDto <Guid> {
  name: string
  viewId: string
  uId: string
  uKey: string
  serialNumber: string
  relay1Time: number
  beepType: number
  beepTime: number
}

export class CreateOrUpdateEquipmentDto {
  public name: string = '';
  public viewId: string = '';
  public uId: string = '';
  public uKey: string = '';
  public serialNumber: string = '';
  public relay1Time: number | undefined;
  public beepType: number | undefined;
  public beepTime: number | undefined;
}

export class GetListEquipmentDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
}
