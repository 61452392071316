import request from '@/utils/request';
import CrudAppService from '@/framework/CrudAppService';
import {EquipmentDto, CreateOrUpdateEquipmentDto, GetListEquipmentDto} from '@/framework/models/Equipment';
import {Guid} from 'guid-typescript';

export default class EquipmentApi extends CrudAppService <EquipmentDto, EquipmentDto, Guid,
  GetListEquipmentDto, CreateOrUpdateEquipmentDto, CreateOrUpdateEquipmentDto> {
  constructor() {
    super(`/keep-fit/equipment`);
  }
}
